<template>
  <div>
       
      <!-- <v-list v-if="!ActiveEntity && DataNavlists && DataNavlists.length > 0">
          <v-list-item :to="dir.Path" class="detailslistoutline" v-for="dir in DataNavlists" :key="dir.itemObjKey">
              <v-list-item-avatar>
                      <v-icon>{{dir.icon}}</v-icon>
                  </v-list-item-avatar>
              <v-list-item-content>                  
                  <v-list-item-title>
                    {{dir.title}}
                  </v-list-item-title>
              </v-list-item-content>
          </v-list-item>
      </v-list> -->
      <!-- <ModuleValidator  v-if="!ActiveEntity" :SingleRecordNavMenuItem="SingleRecordNavMenuItem" :ActiveDB="ActiveDB" :System="System" :SummmaryOnly="true"
      @InvokeConfigureAction="InvokeConfigureAction"
              :Module="{Name: 'Database Builder'}" :SystemEntities="RelevantAppEntities" :CategoricalNavlists="CategoricalNavlists" :SitePages="SitePages"
                :RADB="RADB" :RAApp="RAApp" :SystemGuides="SystemGuides"/> -->
      <v-list v-if="!ActiveEntity">
          <v-list-item @click="ActivateActiveEntity(ent)" class="detailslistoutline" v-for="ent in RelevantAppEntities" :key="ent.itemObjKey">
              <v-list-item-avatar>
                      <v-icon>{{ent.Table_Icon}}</v-icon>
                  </v-list-item-avatar>
              <v-list-item-content>                  
                  <v-list-item-title>
                    {{ent.DisplayName}}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                      {{ent.SingleName}}
                  </v-list-item-subtitle>
              </v-list-item-content>
          </v-list-item>
      </v-list>
      
      <v-list v-if="!ActiveEntity">
           <v-card-title class="background darken-1 mediumoverline justify-center">
             Available Plugins
          </v-card-title>
          <v-card-text>
        <RAPluginCarousel :AppisDarkMode="AppisDarkMode" class="my-3" :GuideList="RAPluginsMatched(['Database Builder','DBB','Social Network Builder','SNB','Website Builder','WB','General'])" :tab="systemguideelmnt"
        :ListPreviewOnly="false" :Squished="true" :PluginPicker="true" @PickPlugin="PickPlugin"
            />
          </v-card-text>
          </v-list>
      <v-card-text v-if="ActiveEntity && !SecurityView">
          <v-card-title class="background darken-1 mediumoverline justify-center">
              <v-btn dark @click="ActiveEntity = ''">Back</v-btn><v-spacer>
                  </v-spacer>
              {{ActiveEntity.DisplayName}}<v-spacer>
                  </v-spacer><v-btn dark @click="ToggleSecurityView()">Security Rules</v-btn>
          </v-card-title>
          <v-tabs centered>
               <v-tab>Validator</v-tab>
              <v-tab-item>
                <ModuleValidator :SingleRecordNavMenuItem="SingleRecordNavMenuItem" :ActiveDB="ActiveDB" :System="System" :SummmaryOnly="true" @InvokeConfigureAction="InvokeConfigureAction"
              :Module="{Name: 'Database Builder'}" :SystemEntities="[ActiveEntity]" :CategoricalNavlists="CategoricalNavlists" :SitePages="SitePages"
                :RADB="RADB" :RAApp="RAApp" :SystemGuides="SystemGuides"/>  
              </v-tab-item>
               <v-tab v-if="ActiveEntity.Entity_Type.Name === 'Cash Loan'">Loan Configuration</v-tab>
              <v-tab-item v-if="ActiveEntity.Entity_Type.Name === 'Cash Loan'">
               <v-card-text>
                <v-switch @change="UpdateEntityConfig('InvoicetoUserCompany',InvoicetoUserCompany)" v-if="UserCompaniesActive" v-model="InvoicetoUserCompany" label="Invoices to User Company"/>
               </v-card-text>
               <v-card-title class="mediumoverline">
                Loan Packages
                <v-spacer></v-spacer>
                 <v-btn @click="AddLoanPackage()">Add Package</v-btn>
                 <!-- <v-btn @click="onceoffautonr()">BS</v-btn> -->
               </v-card-title>
               <v-card-text>
                   <v-expansion-panels>
                    <v-expansion-panel v-for="pack in LoanPackages" :key="pack.itemObjKey">
                        <v-expansion-panel-header>
                            {{pack.Name}}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-text-field v-model="pack.Name" label="Name"/>
                            <v-text-field :rules="PercentageRules(pack.Interest)" v-model.number="pack.Interest" label="Interest"/>
                            <v-select
                                v-model="pack.PaymentSchedule"
                                label="Payment Schedule"
                                :items="['Weekly','Monthly','Annual']"
                            />
                            <v-text-field v-model.number="pack.Period" label="Period"/>
                           <ContentEditableField style="padding:15px;"
                       :FieldObject="pack" :FieldName="'Description'"
                       :FieldValue="pack.Description" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
                            <input :id="pack.Name+'upload file'"
                            @change="TermsDocumentFileSelect($event,pack)" style="visibility: hidden;display: none;"
                        type="file">
                        <label :for="pack.Name+'upload file'"><span v-if="pack.TermsDocument" class="texbtn btnhover elevation-1">
                        <v-icon class="btnhover" small>mdi-file</v-icon>Change Terms Doc
                        </span><span v-if="!pack.TermsDocument" class="texbtn btnhover elevation-1">
                            <v-icon class="btnhover" small>mdi-file</v-icon>Select Terms Doc
                        </span>
                        </label>
                       <v-card-actions>
                        <v-btn @click="SaveLoanPackage(pack)" dark color="success">Save</v-btn>
                       </v-card-actions>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                   </v-expansion-panels>
                   
               </v-card-text>               
              </v-tab-item>
              <v-tab>Basic</v-tab>
              <v-tab-item>
         <QuickEditComponent :System="System" :SystemEntities="SystemEntities" :Record="ActiveEntity" :QuickEditSections="QuickEditSections"
            @UpdateContentEditableField="UpdateContentEditableField" @UploadFileSelect="UploadFileSelect"
            />   
            <v-select clearable @change="UpdateDefaultSorting()" v-model="ActiveEntity.Default_Sort_Fields" multiple :items="SortHeaders" label="Default Sorting" />
            <v-select clearable @change="UpdateDefaultBoard()" v-if="BoardOptions && BoardOptions.length > 0" v-model="ActiveEntity.Default_Board" :items="BoardOptions" item-text="Name" label="Default Board" />
              </v-tab-item>
              <v-tab @click="subcolstep = 1">Galleries</v-tab>
              <v-tab-item>
                   <!--ADD FROM RESPECTIVE BUILDERS <v-card-title>
                      <v-spacer>
                      </v-spacer>
                      <v-btn outlined color="blue">Add</v-btn>
                  </v-card-title> -->
                   <v-window v-model="subcolstep">
                <v-window-item :value="1">
                    <v-card-text>
                        <v-list>
                      <v-list-item @click="ConfigureTable(gal)" class="detailslistoutline" v-for="gal in ActiveEntity.Galleries" :key="gal.itemObjKey">
                         {{gal.Name}}
                      </v-list-item>
                  </v-list>
                    </v-card-text>
                </v-window-item>
                <v-window-item :value="2">
                    <v-card-text>
                        <v-btn @click="CancelTableOptionDialog()">Back</v-btn>
                        <CabinetORSubcolConfig :System="System" :SystemEntities="SystemEntities" :NewTableOption="NewTableOption"
                        :SampleTableOption="SampleTableOption" @Onepropemit="Onepropemit" @NoPropsemit="NoPropsemit" :CurrentEntity="SelectedEntity"
                        />
                    </v-card-text>
                </v-window-item>
                   </v-window>   
              </v-tab-item>
              <v-tab @click="subcolstep = 1">Document Registers</v-tab>
              <v-tab-item>
                  <!--ADD FROM RESPECTIVE BUILDERS <v-card-title>
                      <v-spacer>
                      </v-spacer>
                      <v-btn outlined color="blue">Add</v-btn>
                  </v-card-title> -->
                   <v-window v-model="subcolstep">
                <v-window-item :value="1">
                    <v-card-text>
                        <v-list>
                      <v-list-item @click="ConfigureTable(gal)" class="detailslistoutline" v-for="gal in ActiveEntity.DocumentRegisters" :key="gal.itemObjKey">
                         {{gal.Name}}
                      </v-list-item>
                  </v-list>
                    </v-card-text>
                </v-window-item>
                <v-window-item :value="2">
                    <v-card-text>
                        <v-btn @click="CancelTableOptionDialog()">Back</v-btn>
                        <CabinetORSubcolConfig :System="System" :SystemEntities="SystemEntities" :NewTableOption="NewTableOption"
                        :SampleTableOption="SampleTableOption" @Onepropemit="Onepropemit" @NoPropsemit="NoPropsemit" :CurrentEntity="SelectedEntity"
                        />
                    </v-card-text>
                </v-window-item>
                   </v-window>
              </v-tab-item>
              <v-tab @click="subcolstep = 1">SubCollections</v-tab>
              <v-tab-item>
                   <!--ADD FROM RESPECTIVE BUILDERS <v-card-title>
                      <v-spacer>
                      </v-spacer>
                      <v-btn outlined color="blue">Add</v-btn>
                  </v-card-title> -->
                   <v-window v-model="subcolstep">
                <v-window-item :value="1">
                    <v-card-text>
                        <v-list>
                      <v-list-item @click="ConfigureTable(gal)" class="detailslistoutline" v-for="gal in ActiveEntity.SubCollections" :key="gal.itemObjKey">
                         {{gal.Name}}
                      </v-list-item>
                  </v-list>
                    </v-card-text>
                </v-window-item>
                <v-window-item :value="2">
                    <v-card-text>
                        <v-btn @click="CancelTableOptionDialog()">Back</v-btn>
                        <CabinetORSubcolConfig :System="System" :SystemEntities="SystemEntities" :NewTableOption="NewTableOption"
                        :SampleTableOption="SampleTableOption" @Onepropemit="Onepropemit" @NoPropsemit="NoPropsemit" :CurrentEntity="SelectedEntity"
                        />
                    </v-card-text>
                </v-window-item>
                   </v-window>                  
              </v-tab-item>
          </v-tabs>    
      </v-card-text>
      <v-card-text v-if="SecurityView">
          <SecurityOverview :System="System" :SystemEntities="SystemEntities" :ActiveEntity="ActiveEntity"
           :CategoricalNavlists="CategoricalNavlists" :RADB="RADB"
          />
      </v-card-text>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import RAPluginCarousel from '@/components/WebPages/RenderComponents/RAPluginCarousel';
import SecurityOverview from '@/components/Database/SecurityOverview';
import QuickEditComponent from '@/components/Database/QuickEditComponent'
import ModuleValidator from '@/components/SuiteBuilder/Main/ModuleValidator'
import ContentEditableField from '@/components/Database/ContentEditableField'
import CabinetORSubcolConfig from '@/components/Database/CabinetORSubcolConfig';
export default {
    props: ['System','SystemEntities','RADB','CategoricalNavlists','ActiveSession','SystemGuides'],
    components: {RAPluginCarousel,SecurityOverview,QuickEditComponent,ModuleValidator,CabinetORSubcolConfig,ContentEditableField},
    data() {
        return {
            InvoicetoUserCompany: false,
            LoanPackages: [
                {Name: 'Month End',Description: 'Lorem ipsum borther mother and son',Interest: 0.69,Period: 1},
                {Name: '2 Months',Description: 'Lorem ipsum borther mother and son',Interest: 0.79,Period: 2},
                {Name: '3 Months',Description: 'Lorem ipsum borther mother and son',Interest: 0.93,Period: 3}
            ],
            NewTableOption: '',
            SampleTableOption: '',
            subcolstep: 1,
            systemguideelmnt: {			
              HideGuidesCarousel: false,						
                RowColor: '#00ffff',									
                Name: 'System Guide',									
                ShowComponents: true,									
                Icon: 'mdi-card-text',									
                Title: 'About',									
                Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,		
                Elevation: 6,
                BoxTransparency: '',
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:6,									
                FlexLarge: 8,									
                FlexMedium: 10,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,									
            },
            SecurityView: false,
            ActiveEntity: '',
        }
    },	
    computed:{
        UserCompaniesActive(){
        return this.PluginDataBase && this.PluginDataBase.User_Companies && this.PluginDataBase.User_Companies.Active
      },
      PluginDataBase(){
      return this.$store.state.PluginDataBase
    },
        SortHeaders(){
            return this.EntityHeaders.map(header => {
                return header.id
            })
        },
        EntityHeaders(){
            return this.ActiveEntity.AllFields.filter(field => {
                return field.IsHeader
            })
        },
        BoardOptions(){
        return this.EntityHeaders.filter(field => {
          return field.Type === 'Option Set' && field.IsBoard
        })
      },
        EntityCollectionRef(){
        return db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.ActiveEntity.id)
      },
        SelectedEntity(){
            if(this.NewTableOption){
                let entity = Object.assign({},this.NewTableOption)
                entity.AllFields = entity.Headers
                return entity
            }
            else{
                return this.ActiveEntity
            }
        },
        SystemisRA(){
        return process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID
        },
        NeedsRASync(){
            return !this.SystemisRA
        },
        RAConfigDB(){
           return this.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.ActiveEntity.id)           
        },
        DataNavlists(){
            if(this.ActiveSession.title === 'Directories'){
            let storeproductentities = this.AppEntities.filter(entity => {
                return entity.Entity_Type && entity.Entity_Type.Name === 'Inventory Article'
            }).map(entity => {
                let menuobj = {
                        title: entity.DisplayName,
                        icon: entity.Table_Icon,
                        ItemPass: true,
                        id: entity.id,
                        IsRoute: true,
                        Path: '/DirectoryBuilder/'+entity.id
                        }
                return menuobj
            })
            let classifiedentities = this.AppEntities.filter(entity => {
                return entity.Entity_Type && entity.Entity_Type.Name === 'Classified Listing'
            }).map(entity => {
                let menuobj = {
                        title: entity.DisplayName,
                        icon: entity.Table_Icon,
                        ItemPass: true,
                        id: entity.id,
                        IsRoute: true,
                        Path: '/DirectoryBuilder/'+entity.id
                        }
                return menuobj
            })
            let datanavlists = storeproductentities.concat(classifiedentities,[
                {
                title: 'Classifieds',
                icon: 'mdi-newspaper-variant-outline',
                ItemPass: this.ActiveSuiteApp.Plugins.find(obj => obj.id === 'Classifieds'),
                IsRoute: true,
                Path: '/DirectoryBuilder/Classifieds'
            },{
                title: 'Group Categories',
                icon: 'mdi-account-group',
                ItemPass: this.ActiveSuiteApp.Plugins.find(obj => obj.id === 'Site_Groups'),
                IsRoute: true,
                Path: '/DirectoryBuilder/Group_Categories'
            },{
                title: 'Group Featured Members',
                icon: 'mdi-account-star-outline',
                ItemPass: this.ActiveSuiteApp.Plugins.find(obj => obj.id === 'Group_Featured_Members'),
                IsRoute: true,
                Path: '/DirectoryBuilder/Group_Featured_Members'
            },{
                title: 'Business Members',
                icon: 'mdi-smart-card',
                ItemPass: this.ActiveSuiteApp.Plugins.find(obj => obj.id === 'Digital_Business_Cards'),
                IsRoute: true,
                Path: '/DirectoryBuilder/Business_Members'
            }
            ])
            return datanavlists.filter(item => {
                return item.ItemPass
            })
            }
        },
        StoreOrderEntity(){
            return this.ActiveEntity.Entity_Type.Name === 'Store Order'
        },
        StoreProductEntity(){
            return this.ActiveEntity.Entity_Type.Name === 'Store Product'
        },
        InventoryArticleEntity(){
            return this.ActiveEntity.Entity_Type.Name === 'Inventory Article'
        },
        ScheduledBooking(){
            return this.ActiveEntity.Entity_Type.Name === 'Scheduled Booking'
        },
        CustomDirectory(){
            return this.ActiveEntity.Entity_Type.Name === 'Classified Entity'
        },
        CustomCalendar(){
            return this.ActiveEntity.Entity_Type.Name === 'Calendar'
        },
        TicketTable(){
            return this.ActiveEntity.Entity_Type.Name === 'Ticketing Table'
        },
        StoreOrderEntity(){
            return this.ActiveEntity && this.ActiveEntity.Entity_Type.Name === 'Store Order'
        },
        QuickEditSections(){
            //WE ALSO CATER FOR DIRECTORIES TO REPLACE SuiteBuilder/SuiteApps/Settings/Directories.vue ???? OR USE THAT DOWN HERE???
          let sect1fields = ['Table_Icon','Description','Has_Wiki']
        //   /'StoreOrderEntity','Precede_with_Quote'(!E-COMMERCE PLUGIN),'Warehouse_Dispatch_After' not sure if we keeping this route...install store order entity when needed right? base template, period right? but yes then config
        //yes don't forget store order is new entiy type
          let basic = [
            {Header: 'Basic',Type: 'Fields',Fields: this.PrepQuickFieldSection(sect1fields),Class: 'flex xl6 lg6 md6 sm12 xs12'},
          ]
          let typebased = []
          if(this.ScheduledBooking){
              //Has_Location
              let sect2fields = ['Hide_by_Day_Filter','Guests_Can_Book','Guests_Only','Bookings_have_Dialog']
              typebased = [
                {Header: 'Advanced',Type: 'Fields',Fields: this.PrepQuickFieldSection(sect2fields),Class: 'flex xl6 lg6 md6 sm12 xs12'},  
              ]
          }
          if(this.StoreOrderEntity){
              //Has_Location, Precede_with_Quote? !E-COMMERCE PLUGIN
              let sect2fields = ['COD_Payment_Terms']
              typebased = [
                {Header: 'Advanced',Type: 'Fields',Fields: this.PrepQuickFieldSection(sect2fields),Class: 'flex xl6 lg6 md6 sm12 xs12'},  
              ]
          }
          if(typebased.length === 0){
              basic[0].Class = 'flex xl12 lg12 md12 sm12 xs12'
          }
          return basic.concat(typebased)
        },
        RelevantAppEntities(){
            //console.log('AppEntitiesAppEntitiesAppEntities',this.AppEntities)
            return this.AppEntities.filter(ent => {
                if(this.ActiveSession.title === 'Database'){
                    return ent.Entity_Type.Name === 'Standard' || ent.Entity_Type.Name === 'Cash Loan' 
                }
                else if(this.ActiveSession.title === 'Calendars'){
                  return ent.Entity_Type.Name === 'Calendar' || ent.Entity_Type.Name === 'Scheduled Booking' 
                }  
                else if(this.ActiveSession.title === 'Store'){
                  return ent.Entity_Type.Name === 'Store Order' || ent.Entity_Type.Name === 'Sales Quote' || ent.Entity_Type.Name === 'Sales Invoice' 
                }    
                else if(this.ActiveSession.title === 'Directories'){
                  return ent.Entity_Type.Name === 'Classified Entity' || ent.Entity_Type.Name === 'Inventory Article' 
                }   
                else if(this.ActiveSession.title === 'Warehouses'){
                  return ent.Entity_Type.Name === 'Inventory Article' || ent.Entity_Type.Name === 'Store Product' || ent.Entity_Type.Name === 'Store Supplier' || ent.Entity_Type.Name === 'Purchase Invoice'
                  
                }        
            })
        },
        RAPlugins(){
            return this.$store.state.RAPlugins
        },
        AppEntities(){
            return this.SystemEntities.filter(ent => {
                return ent.Primary_Appid === this.ActiveSuiteApp.id
                //DEFINITELY NOT SECONDARY AND SHARED ENTITIES, BUT ALSO SHOULD NOT EVEN REACH THIS POINT FROM SECONDARIES
            })
        },
        ActiveSuiteApp(){
            return this.$store.state.ActiveSuiteApp
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        ConfigMasterEntityFields(){
            return this.$store.state.EntityMasterFields.map(field => {
                if(field.IsConditionalRender && field.ConditionalOptionField === 'Entity_Type'){
                    //console.log(field.id,field.ConditionalOptions)
                }
                return field
            })
        },
        ConfigMasterEntity(){
            let obj = {
                AllFields: this.ConfigMasterEntityFields
            }
            return obj
        },
    },
    watch: {
        ActiveEntity: {
            handler: function(newvalue, oldvalue) {
              if(newvalue !== oldvalue){
                if(typeof this.ActiveEntity.InvoicetoUserCompany !== 'undefined'){
                    this.InvoicetoUserCompany = this.ActivateActiveEntity.InvoicetoUserCompany
                }
              }
            },deep: true
        },
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['ReceiveTermsDoc']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        this.ScrolltoTop()
    },
    methods:{
        UpdateEntityConfig(prop,value){
            if(this.NeedsRASync){
            this.RAConfigDB.update({
               [prop]: value
            }).then(radoc => {
                this.EntityCollectionRef.update({
                    [prop]: value
                })
             })  
           }
           else{
            this.EntityCollectionRef.update({
                    [prop]: value
                })
           }
        },
        onceoffautonr(){
             let storordent = this.SystemEntities.find(obj => obj.Entity_Type.Name === 'Loan Order')
            let SONumberAuto = {
              id: storordent.id+'_Order_Number',
              Digits: 6,
              DisplayName: 'Order Number',
              Last_Numbers: [],
              Last_Number: 0,
              Last_Value: '',
              Name: 'Order_Number',
              NumberingString: '0',
              Type: 'AutoNumber Field',
              Suffixes: [],
              Prefixes: [
                {Prefix_Value: 'SO-',Type: 'Standard'}
              ]
            }
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('AutoNumbers').doc(SONumberAuto.id).set(SONumberAuto)
        },
        AddLoanPackage(){
            let newpack = {
                Name: 'Month End',
                Period: 1,
                Interest: 1,
                Description: `<div style="text-align: center;"><span style="font-size: 0.875rem; letter-spacing: 0.00714286em; font-family: var(--app-bodyfont) ;">A single instalment at the end of the transacting month</span></div>`
            }
            let colref = db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.ActiveEntity.id).collection('LoanPackages')
            let ref = colref.doc()
            let newdocid = ref.id
            colref.doc(newdocid).set(newpack).then(addeddoc => {
                
            })
        },
        TermsDocumentFileSelect(event,pack){
            let file = event.target.files[0]
            pack.UploadFile = file
            pack.FileName = file.name
        },
        UploadFileSelect(file,field,Record){
        console.log(file,field,Record)
        field.UploadFile = file
        field.FileName = file.name
        },
        ReceiveTermsDoc(payload){
            console.log(payload)
            let colref = payload.PassedObj.colref
            let fieldname = payload.PassedObj.fieldname
            let newdocid = payload.PassedObj.newdocid
            let pack = payload.PassedObj.pack
            delete pack.UploadFile
            delete pack.FileName
            pack[fieldname] = payload.UploadObject
            console.log(colref,pack,newdocid)
            this.FinalizeLoanPackageUpdate(colref,pack,newdocid)
        },
        SaveLoanPackage(pack){
            let colref = db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.ActiveEntity.id).collection('LoanPackages')
            let newdocid = ''
            if(pack.id){
                newdocid = pack.id
            }
            else{
                let ref = colref.doc()
                newdocid = ref.id
            }
            if(pack.UploadFile){
                console.log(pack)
                 let payload = {
                    file: pack.UploadFile,
                    filename: pack.FileName,
                    storepath: this.ActiveEntity.id+'/LoanPackageTerms/'+newdocid+'/'+new Date()+'/'+pack.FileName,
                    returnmethod: 'ReceiveTermsDoc',
                    PassedObj: {colref: colref,pack: pack,newdocid: newdocid,fieldname: 'TermsDocument'}
                }
                this.$store.commit('SetSocialItemInteractMethodProp',payload) 
                this.$store.commit('SetSocialItemInteractMethod','ProcessSingleFileUpload')
            }
            else{
                this.FinalizeLoanPackageUpdate(colref,pack,newdocid)               
            }
            
        },
        FinalizeLoanPackageUpdate(colref,pack,newdocid){
             colref.doc(newdocid).set(pack).then(addeddoc => {
                
            })
        },
        UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
            FieldObject[prop] = value
        },
        PercentageRules(){
            return [ 
                v => !!v || "This field is required",
                v => ( v && v >= 0 ) || "Loan should be above 0",
                v => ( v && v <= 1 ) || "It cannot be above 1",
            ]
        },
        UpdateDefaultSorting(){
           if(this.NeedsRASync){
            this.RAConfigDB.update({
                Default_Sort_Fields: this.ActiveEntity.Default_Sort_Fields
            }).then(radoc => {
                this.EntityCollectionRef.update({
                    Default_Sort_Fields: this.ActiveEntity.Default_Sort_Fields
                })
             })  
           }
           else{
            this.EntityCollectionRef.update({
                    Default_Sort_Fields: this.ActiveEntity.Default_Sort_Fields
                })
           }
        },
        UpdateDefaultBoard(){
           if(this.NeedsRASync){
            this.RAConfigDB.update({
                Default_Board: this.ActiveEntity.Default_Board
            }).then(radoc => {
                this.EntityCollectionRef.update({
                    Default_Board: this.ActiveEntity.Default_Board
                })
             })  
           }
           else{
            this.EntityCollectionRef.update({
                    Default_Board: this.ActiveEntity.Default_Board
                })
           }
        },
        NoPropsemit(MethodName){
            this[MethodName]()
        },
        Onepropemit(MethodName,prop){
            console.log(MethodName,prop)
            this[MethodName](prop)
        },
        ResetTableOptionDialog(){
            this.subcolstep = 1
            
            this.SampleTableOption.Entityid = this.NewTableOption.Name.split(' ').join('_')
            this.SampleRecord.SubCollections = []
            this.SampleRecord.SubCollections.push(this.NewTableOption)
            if(this.SampleTableOption.OptionType === 'SubCollection'){
                this.SampleTableOption.EntitySource = 'SubCollection'
            }
            console.log(this.NewTableOption,this.SampleTableOption)
            setTimeout(() => {
                    this.subcolstep = 2
            }, 10);
        },
        CancelTableOptionDialog(){
            this.subcolstep = 1,
            this.NewTableOption = ''
            this.SampleTableOption = ''
            console.log(this.NewTableOption)
            //this.NewTableOption = ''
        },
        ActivateNewFieldDialog(field,AddingNewField,ListPanel){
            console.log('field',field)
            this.NewField = Object.assign({},field)
            this.NewFieldDialog = true
            this.AddingNewField = AddingNewField
            this.ListPanel = ListPanel
            console.log(this.EntityCollectionRef,field)
            // if(tableoption){
            //     this.NewTableOption = tableoption 
            // }
        },
        CancelNewFieldDialog(){
            this.NewFieldDialog = false,
            this.NewField = ''
             this.AddingNewField = false
             this.ListPanel = ''
            //this.NewTableOption = ''
        },
        AddTableOption(opt){
            if(!opt.DisplayName){
             opt.DisplayName = opt.Name   
            }            
            opt.Name = opt.Name.split(' ').join('_')     
            console.log(opt)
            if(opt.Options){
            opt.PrimaryOptions = opt.Options
            opt.PrimaryOptionsInteger = opt.lastassignedinteger
            delete opt.Options
            delete opt.lastassignedinteger     
            this.NoPropsemit('CancelOptionSetDialog')
            }
            //.DocRegAdditionalFields.map?
            opt.MultipleFileUploadType = opt.OptionType
            opt.SectionType = opt.OptionType                
            this.FinalAddTableOption(opt)
            
        },
        FinalAddTableOption(opt){
            console.log(this.EntityCollectionRef,opt.ConfigCollection,opt)
            console.log(this.EntityCollectionRef.collection(opt.ConfigCollection).doc(opt.Name))
            console.log('CheckObjectforUndefined')
            this.CheckObjectforUndefined(1,opt,'('+opt.Name+')')
            if(!this.NeedsRASync){
                if(!opt.id){
                this.EntityCollectionRef.collection(opt.ConfigCollection).doc(opt.Name).set(opt)   
                }
                else{
                    if(opt.ConfigCollection === 'SubCollections'){
                        this.EntityCollectionRef.collection(opt.ConfigCollection).doc(opt.Name).set(opt)
                    }
                }
            }
            else{
                if(!opt.id){
                    this.RAConfigDB.collection(opt.ConfigCollection).doc(opt.Name).set(opt).then(radoc => {
                        this.EntityCollectionRef.collection(opt.ConfigCollection).doc(opt.Name).set(opt)
                    })  
                }
                else{
                    this.RAConfigDB.collection(opt.ConfigCollection).doc(opt.id).set(opt).then(radoc => {
                        this.EntityCollectionRef.collection(opt.ConfigCollection).doc(opt.id).set(opt)
                    })
                }
            }
            
        },
        ConfigureTable(tab){
            console.log(tab)
            if(tab.MultipleFileUploadType === 'SubCollection'){
                let opt = this.ActiveEntity.SubCollections.find(obj => obj.id === tab.id)
                this.ActivateTableOptionDialog(opt)
            }
            else if(tab.MultipleFileUploadType === 'Document Register'){
                let opt = this.ActiveEntity.DocumentRegisters.find(obj => obj.id === tab.id)
                this.ActivateTableOptionDialog(opt)
            }
            else if(tab.MultipleFileUploadType === 'Gallery'){
                let opt = this.ActiveEntity.Galleries.find(obj => obj.id === tab.id)
                this.ActivateTableOptionDialog(opt)
            }
        },
        ActivateTableOptionDialog(tableoption){
            this.NewTableOption = Object.assign({},tableoption)
            if(this.NewTableOption.MultipleFileUploadType === 'SubCollections' && this.NewTableOption.Type === 'Lookup' && this.NewTableOption.LookupBuilds){
                  this.NewTableOption.LookupBuilds = this.NewTableOption.LookupBuilds.map(build => {
                  let lookupbuild = this.SystemEntities.find(obj => obj.id === build.id)
                  console.log(build.id,this.NewTableOption,this.NewTableOption.LookupBuilds,lookupbuild)
                  if(lookupbuild){
                    build.AllFields = lookupbuild.AllFields
                    build.Entity_Type = lookupbuild.Entity_Type
                  }
                  return build
                  })
            }
             //.DocRegAdditionalFields.map?
            this.SampleTableOption = Object.assign({},tableoption)
            this.SampleTableOption.BGColor = {									
                    hex: '#ffffff',
                    hexa: '#ffffffff',									
            }
            console.log(this.SampleTableOption)
            this.subcolstep = 2
        },
        ScrolltoTop(){
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
        InvokeConfigureAction(elmnt){
            //console.log(elmnt)
            if(this.ActiveEntity && elmnt.Check === 'Directories Setup'){
                let dir = this.DataNavlists.find(obj => obj.id === this.ActiveEntity.id)
                console.log(dir)
                this.$router.push(dir.Path)
            }
            else if(this.ActiveEntity && elmnt.Check === 'Directory Entries'){
                this.$router.push('/'+this.ActiveEntity.DisplayName.split(' ').join(''))
            }
            else if(this.ActiveEntity && elmnt.Check === 'BU Security Roles' || this.ActiveEntity && elmnt.Check === 'Custom Security Roles'){
                this.SecurityView = true
            }
            this.ScrolltoTop()
        },
        PrepQuickFieldSection(fields){
        return fields.map(field => {
            let fieldobj = Object.assign({},this.ConfigMasterEntity.AllFields.find(obj => obj.id === field))
            if(fieldobj.Type === 'Date' && typeof fieldobj.DateMenu === 'undefined'){
              fieldobj.DateMenu = false
              if(typeof this.ActiveEntity[fieldobj.Name] !== 'undefined' && typeof this.ActiveEntity[fieldobj.Name].toDate !== 'undefined'){
                let dateobject = this.ActiveEntity[fieldobj.Name].toDate()
                this.ActiveEntity[fieldobj.Name] = format(dateobject,'yyyy-MM-dd')
              }
            }
            return fieldobj
          })
      },
        PickPlugin(plug){
            this.$emit('PickPlugin',plug)
        },
        ToggleSecurityView(){
            this.SecurityView = !this.SecurityView
        },
        RAPluginsMatched(categorymatches){
        let finalcategories = categorymatches.map(cat => {
          return cat.split(' & ')
        }).flat()
        return this.RAPlugins.filter(plug => {
          let plugcat = plug.Module_Category.Name.split(' & ').flat()
          return finalcategories.some(r=> plugcat.includes(r))
          //|| plug.Module_Category.Name === 'General'
        }).filter(item => {
           return item.Exclusively_as_Extension && item.Level.Name === 'App'
        }).filter(plug => {
          let newappplugins = this.ActiveSuiteApp.Plugins.map(plug => {
            return plug.id
          })
          //console.log(newappplugins)
          return !newappplugins.includes(plug.id)
        })
        },
        ActivateActiveEntity(ent){
            if(ent.Entity_Type.Name === 'Cash Loan'){
                if(ent.LoanPackages){
                   this.LoanPackages = ent.LoanPackages    
                }
            }
            this.ActiveEntity = ent
        },
        CheckObjectforUndefined(lvl,obj,path){
      for(var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          let newpath = path+'/'+prop
          if(typeof obj[prop] === 'undefined'){
            console.log('LVL '+lvl+' prop UNDEFINED!!! '+newpath,obj)
          }
          else{
            if(!Array.isArray(obj[prop])){
              //console.log('LVL '+lvl+' prop OK '+newpath+' as ',obj[prop])
              //repeat this function somehow
              if(typeof obj[prop] !== 'string'){
                this.CheckObjectforUndefined(lvl-1+2,obj[prop],newpath)
              }
              
            }
            else{              
              //call array undefined check
              this.CheckArrayforUndefined(lvl-1+2,obj[prop],newpath)
            }
          }
        }
      }
    },
    CheckArrayforUndefined(lvl,obj,path){
      //console.log('checkingprop '+checkingprop+' on path '+path)
      obj.map(arrayrecord => {
        for(var prop in arrayrecord) {
          if (arrayrecord.hasOwnProperty(prop)) {
            let newpath = path+'/'+prop
            if(typeof arrayrecord[prop] === 'undefined'){
              console.log('LVL '+lvl+' array prop UNDEFINED!!! '+newpath,arrayrecord)
            }
            else{
              //console.log('LVL '+lvl+' prop OK '+newpath+' as ',arrayrecord[prop])
              if(!Array.isArray(arrayrecord[prop])){
                if(typeof arrayrecord[prop] !== 'string'){
                this.CheckObjectforUndefined(lvl-1+2,arrayrecord[prop],newpath)
                }
              }
              else{
                this.CheckArrayforUndefined(lvl-1+2,arrayrecord[prop],newpath)
                //prop is array check for third prop
                // arrayrecord[prop].map(thirdrecord => {
                // })
              }
            }
          }
        }
      })
    },
    }
}
</script>

<style>

</style>



